<template>
  <b-card
    v-loading="loading"
    no-body
  >
    <b-card-header>
      <b-card-title class="mb-1">
        PIA评估
      </b-card-title>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="200"
      class="my-2"
      :options="goalOverviewRadialBar"
      :series="goalOverview.series"
    />
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="
          border-top border-right
          d-flex
          align-items-between
          flex-column
          py-1
        "
      >
        <b-card-text class="text-muted mb-0">
          进行中数量
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ goalOverview.processing }}
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          完成数量
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ goalOverview.finished }}
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { AssessFinishStatistics, GetPiaSatisfaction } from '@/api/workbench/workbench'
import { assessment } from '@/@core/utils/constant'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },
  data() {
    return {
      loading: false,
      goalOverviewRadialBar: {
        chart: {
          height: 200,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: ['#20E647'],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '70%',
              background: '#482C50',
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: '#ffffff',
                fontSize: '13px',
              },
              value: {
                color: '#ffffff',
                fontSize: '30px',
                show: true,
              },
              total: {
                show: true,
                label: '完成度',
                color: '#ffffff',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#87D4F9'],
            inverseColors: true,
            opacityFrom: 0,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
        labels: ['完成度'],
      },
      goalOverview: {
        processing: 0,
        finished: 0,
        series: [],
      },
    }
  },
  created() {
    this.getPiaSatisfaction()
  },
  methods: {
    getPiaSatisfaction() {
      this.loading = true
      AssessFinishStatistics(assessment[1].assessCode).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          this.goalOverview.finished = resData.data.finishCount
          this.goalOverview.processing = resData.data.editCount
          const finishPrect = parseInt((resData.data.finishCount / resData.data.total) * 100)
          if (resData.data.total === 0) {
            this.goalOverview.series.push(100)
          } else {
            this.goalOverview.series.push(finishPrect || 0)
          }
        }
      }).catch(() => {
        this.loading = false
      })
    },
  },
}
</script>
