<template>
  <div class="workbench">
    <b-row class="match-height">
      <b-col
        md="12"
        xl="8"
      >
        <SystematicStatistics />
      </b-col>
      <b-col
        md="12"
        xl="4"
      >
        <b-card
          class="card-congratulation-medal"
          title="快捷操作"
        >
          <div class="d-flex justify-content-around">
            <b-avatar
              size="75"
              variant="primary"
            >
              <router-link to="/compliance-list">
                快速评估
              </router-link>
            </b-avatar>
            <b-avatar
              size="75"
              variant="success"
            >
              <router-link to="/compliance-analysis">
                认证评估
              </router-link>
            </b-avatar>
            <b-avatar
              size="75"
              variant="danger"
            >
              <router-link to="/pia">
                PIA评估
              </router-link>
            </b-avatar>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        sm="12"
        lg="4"
      >
        <TrackerQuickPIA />
      </b-col>
      <b-col
        sm="12"
        lg="4"
      >
        <TrackerGapPIA />
      </b-col>
      <b-col
        sm="12"
        lg="4"
      >
        <TrackerPIA />
      </b-col>
      <!--<b-col sm="12" lg="3">
        <TrackerCPM />
      </b-col>-->
    </b-row>
    <b-row class="match-height">
      <b-col
        sm="4"
        xl="4"
        lg="2"
      >
        <TrackerStatement />
      </b-col>
      <b-col
        sm="4"
        xl="4"
        lg="2"
      >
        <TrackerAsset />
      </b-col>
      <b-col
        sm="4"
        xl="4"
        lg="2"
      >
        <TrackerCookie />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        sm="4"
        xl="4"
        lg="2"
      >
        <Bar
          v-if="barInit"
          :options="barOption"
        />
      </b-col>
      <b-col
        sm="8"
        xl="8"
        lg="10"
      >
        <PrivateFields />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BAvatar } from 'bootstrap-vue'
import { GetFieldSatisfaction } from '@/api/workbench/workbench'
import SystematicStatistics from './SystematicStatistics.vue'
import TrackerStatement from './TrackerStatement.vue'
import OrgStatics from './OrgStatics.vue'
import TrackerQuickPIA from './TrackerQuickPIA.vue'
import TrackerGapPIA from './TrackerGapPIA.vue'
import TrackerPIA from './TrackerPIA.vue'
import TrackerCookie from './TrackerCookie.vue'
import TrackerCPM from './TrackerCPM.vue'
import TrackerAsset from './TrackerAsset.vue'
import PrivateFields from './PrivateFields.vue'
import Tracker from './Tracker.vue'
import Bar from './Bar.vue'

export default {
  name: 'WorkbenchDemo',
  components: {
    BRow,
    BCol,
    BAvatar,
    SystematicStatistics,
    TrackerStatement,
    OrgStatics,
    TrackerQuickPIA,
    TrackerGapPIA,
    TrackerPIA,
    TrackerAsset,
    TrackerCookie,
    TrackerCPM,
    PrivateFields,
    Tracker,
    Bar,
  },
  data() {
    return {
      barOption: {
        title: '隐私字段数',
        horizontal: true,
        data: [],
      },
      barInit: false,
      barLoading: false,
    }
  },
  created() {
    this.getFieldSatisfaction()
  },
  methods: {
    getFieldSatisfaction() {
      this.loading = true
      GetFieldSatisfaction().then(res => {
        this.barLoading = false
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            this.barOption.data.push({ label: r.gradeName, value: r.fieldCount })
          })
          this.barInit = true
        }
      }).catch(() => {
        this.barLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
.workbench {
  .statisticTitle {
    font-size: 12px;
  }
}
</style>

<style scoped>
a {
  color: white;
}
</style>
