<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="font-weight-bolder">
          部门及用户
        </b-card-title>
      </div>
      <!--/ title and subtitle -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="260"
        :options="barChart.chartOptions"
        :series="barChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  name: 'OrgStatics',
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  data() {
    return {
      barChart: {
        series: [
          {
            data: [3, 9, 20],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.info,
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: ['前端服务器', '后端服务器', '数据库'],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    }
  },
}
</script>
