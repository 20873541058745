<template>
  <b-card
    v-loading="loading"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>个人信息资产</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
                <i
                  class="iconfont"
                  :class="item.icon"
                  style="font-size: 24px"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0" style="font-size:16px">
                {{ item.title }}
              </h4>
              <b-card-text class="mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue'
import { GetAssetsPrivacyFieldSatisfaction, GetProductTotal } from '@/api/workbench/workbench'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      loading: false,
      statisticsItems: [
        {
          icon: 'BoxIcon',
          color: 'light-primary',
          title: 3,
          subtitle: '业务系统',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'icon-yuanshuju-ziduanguanli',
          color: 'light-info',
          title: 0,
          subtitle: '个人信息字段',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'icon-minganguanjianzi',
          color: 'light-success',
          title: 0,
          subtitle: '个人信息数量',
          customClass: '',
        },
        {
          icon: 'icon-gerenxinxi',
          color: 'light-danger',
          title: 0,
          subtitle: '敏感信息数量',
          customClass: 'mb-2 mb-sm-0',
        },
      ],
    }
  },
  created() {
    this.getAssetsPrivacyFieldSatisfaction()
    this.getProductTotal()
  },
  methods: {
    getProductTotal() {
      GetProductTotal().then(res =>{
        const resData = res.data
        if(resData.code === 0) {
          this.statisticsItems[0].title = resData.data.productTotal
        }
      })
    },
    getAssetsPrivacyFieldSatisfaction() {
      this.loading = true
      GetAssetsPrivacyFieldSatisfaction().then(res =>{
        this.loading = false
        const resData = res.data
        if(resData.code === 0) {
          // this.statisticsItems[0].title = resData.data.total
          this.statisticsItems[1].title = resData.data.total
          this.statisticsItems[2].title = resData.data.saveCountShow
          this.statisticsItems[3].title = resData.data.sensitiveSaveCountShow
        }
      }).catch(e=>{
        this.loading = false
      })
    }
  },
}
</script>
