<template>
  <b-card
    v-loading="loading"
    no-body
  >
    <b-card-header>
      <b-card-title class="mb-1">
        隐私声明
      </b-card-title>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="350"
      class="my-2"
      :options="goalOverviewRadialBar"
      :series="goalOverview.series"
    />
    <!--    <b-row class="text-center mx-0">-->
    <!--      <b-col-->
    <!--        cols="6"-->
    <!--        class="-->
    <!--          border-top border-right-->
    <!--          d-flex-->
    <!--          align-items-between-->
    <!--          flex-column-->
    <!--          py-1-->
    <!--        "-->
    <!--      >-->
    <!--        <b-card-text class="text-muted mb-0">-->
    <!--          启用数量-->
    <!--        </b-card-text>-->
    <!--        <h3 class="font-weight-bolder mb-0">-->
    <!--          {{ goalOverview.enabled }}-->
    <!--        </h3>-->
    <!--      </b-col>-->

    <!--      <b-col-->
    <!--        cols="6"-->
    <!--        class="border-top d-flex align-items-between flex-column py-1"-->
    <!--      >-->
    <!--        <b-card-text class="text-muted mb-0">-->
    <!--          停用数量-->
    <!--        </b-card-text>-->
    <!--        <h3 class="font-weight-bolder mb-0">-->
    <!--          {{ goalOverview.disabled }}-->
    <!--        </h3>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { GetStatementReleaseSatisfaction } from '@/api/workbench/workbench'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },
  data() {
    return {
      loading: false,
      goalOverviewRadialBar: {
        labels: ['开启数量'],
        chart: {
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },

          // dropShadow: {
          //   enabled: true,
          //   blur: 3,
          //   left: 1,
          //   top: 1,
          //   opacity: 0.1,
          // },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            // offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            // startAngle: -10,
            // endAngle: 90,
            total: {
              label: '',
            },
            hollow: {
              // size: '100%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '98%',
            },
            dataLabels: {
              name: {
                // show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '200',
                formatter(val, a) {
                  return `${a.config.plotOptions.radialBar.total.label}个`
                },
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          // lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      goalOverview: {
        enabled: 0,
        disabled: 0,
        series: [],
      },
    }
  },
  created() {
    this.getStatementReleaseSatisfaction()
  },
  methods: {
    getStatementReleaseSatisfaction() {
      this.loading = true
      GetStatementReleaseSatisfaction().then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          this.goalOverview.disabled = resData.data.stopCount
          this.goalOverview.enabled = resData.data.enableCount

          // this.goalOverviewRadialBar.plotOptions.radialBar.dataLabels.value.formatter = function (val){
          //   return resData.data.enableCount + '个'
          // }
          this.goalOverviewRadialBar.plotOptions.radialBar.total.label = resData.data.enableCount
          const finishPrect = parseInt((resData.data.enableCount / resData.data.total) * 100)
          this.goalOverview.series.push(finishPrect || 0)
        }
      }).catch(() => {
        this.loading = false
      })
    },
  },
}
</script>
