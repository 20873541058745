<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="font-weight-bolder">
          个人信息资产
        </b-card-title>
      </div>
      <!--/ title and subtitle -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        v-if="init"
        type="bar"
        height="270"
        :options="barChart.chartOptions"
        :series="barChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { GetAssetsSatisfaction } from '@/api/workbench/workbench'

export default {
  name: 'TrackerAsset',
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  data() {
    return {
      init: false, // 后台获取到数据才渲染
      loading: false,
      barChart: {
        series: [
          {
            // data: [500, 5, 1,5],
            name: '',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          },
          colors: $themeColors.info,
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            // categories: ['前端应用', '后端应用', '中间件1','数据库1'],
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    }
  },
  created() {
    this.getAssetsSatisfaction()
  },
  methods: {
    getAssetsSatisfaction() {
      this.loading = true
      GetAssetsSatisfaction().then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          const xData = []
          const yData = []
          resData.data.forEach(r => {
            yData.push(Number(r.assetsCount))
            xData.push(r.typeName)
          })
          this.barChart.series[0].data = yData
          this.barChart.chartOptions.xaxis.categories = xData
          this.init = true
        }
      }).catch(() => {
        this.loading = false
      })
    },
  },
}
</script>
