<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-1">
        {{ options.title }}
      </b-card-title>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="radialBarOption"
      :series="[options.overview.value]"
    />

    <!--data list-->
    <b-row class="text-center mx-0">
      <b-col
        v-for="(item, index) in options.data"
        :key="index"
        :cols="colWidth"
        :class="`border-top d-flex align-items-between flex-column py-1 ` + (index === options.data.length-1 ? '' : 'border-right' )"
      >
        <b-card-text class="text-muted mb-0">
          {{ item.label }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ item.value }}
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        title: '默认标题',
        overview: { label: '完成度', value: 0 },
        data: [
          { label: '进行中', value: 0 },
          { label: '已完成', value: 0 },
        ],
      }),
    },
  },
  data() {
    return {
      loading: false,
      radialBarOption: {
        chart: {
          height: 200,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: ['#20E647'],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '70%',
              background: '#293450',
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: '#fff',
                fontSize: '13px',
              },
              value: {
                color: '#fff',
                fontSize: '30px',
                show: true,
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#87D4F9'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
        labels: [this.options.overview.label],
      },

      // goalOverview: {
      //   processing: 7,
      //   finished: 4,
      //   series: [36.4],
      // },
    }
  },
  computed: {
    colWidth() {
      return 12 / this.options.data.length
    },
  },
  created() {},
  methods: {},
}
</script>
