<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="font-weight-bolder">
          {{ options.title }}
        </b-card-title>
      </div>
      <!--/ title and subtitle -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="100%"
        :options="barChart.chartOptions"
        :series="barChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  props: {
    options: {
      type: Object,
      default: () => ({
        title: '个人信息字段',
        horizontal: true,
        data: [],
      }),
    },
  },
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  data() {
    return {
      barChart: {
        series: [
          {
            name: '',
            data: this.options.data.map(item => item.value),
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          },
          colors: $themeColors.info,
          plotOptions: {
            bar: {
              horizontal: this.options.horizontal,
              barHeight: '30%',
              barWidth: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: this.options.data.map(item => item.label),
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    }
  },

  computed: {
  },
}
</script>
