<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="font-weight-bolder">
          同意及偏好
        </b-card-title>
      </div>
      <!--/ title and subtitle -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        v-if="init"
        type="bar"
        height="270"
        :options="barChart.chartOptions"
        :series="barChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { GetChannelSatisfaction } from '@/api/workbench/workbench'

export default {
  name: 'TrackerCPM',
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  data() {
    return {
      init: false, // 后台获取到数据才渲染
      barChart: {
        series: [
          {
            name: '',
            data: [3, 9, 20, 10],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.error,
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '30%',
              // endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: ['App', '网站', '小程序', '其他'],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    }
  },
  created() {
    this.getChannelSatisfaction()
  },
  methods: {
    getChannelSatisfaction() {
      this.loading = true
      GetChannelSatisfaction().then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          const xData = []
          const yData = []
          resData.data.forEach(r => {
            yData.push(Number(r.channelCount))
            xData.push(r.typeName)
          })
          this.barChart.series[0].data = yData
          this.barChart.chartOptions.xaxis.categories = xData
          this.init = true
        }
      }).catch(() => {
        this.loading = false
      })
    },
  },
}
</script>
<style>
.echarts{
  width:100%;
  height:100%;
}
</style>
