<!-- eslint-disable import/extensions -->
<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-1">
        隐私字段分类
      </b-card-title>
    </b-card-header>

    <b-card-body :style="{height: chartHeight+'px'}">
      <e-charts
        ref="tree"
        theme="ovilia-green"
        :options="tree"
        @click="changeHeight"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import { GetClassTree } from '@/api/workbench/workbench'
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/tree'
import theme from './theme.json'

ECharts.registerTheme('ovilia-red', theme)

export default {
  components: {
    ECharts,
    BCard,
    BCardHeader,
    BCardBody,
  },
  data() {
    return {
      chartHeight: 220,
      tree: {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
        },
        zoom: 2,
        series: [
          {
            type: 'tree',

            data: [],

            top: '1%',
            left: '10%',
            bottom: '1%',
            right: '10%',
            // roam: true,
            symbolSize: 7,
            symbol: 'emptyCircle',

            label: {
              position: 'top',
              verticalAlign: 'middle',
              align: 'right',
              fontSize: 12,
            },

            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
              },
            },

            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
          }],
      },
      loading: false,
    }
  },
  created() {
    this.getClassTree()
  },
  methods: {
    getClassTree() {
      this.loading = true
      GetClassTree().then(res => {
        this.loading = false
        // this.tree.series[0].data = res.data.data
        const treeData = res.data.data
        const allData = {
          name: '',
          children: treeData,
          symbolSize: 0,
        }
        if (treeData.length > 1) {
          treeData.forEach(t => {
            t.lineStyle = {
              color: '#fff',
              width: 0,
            }
          })
          this.tree.series[0].data.push(allData)
          // this.tree.series[0].right = '50%'
          this.tree.series[0].left = '-20%'
        } else {
          this.tree.series[0].data = treeData
          this.tree.series[0].left = '10%'
        }
      })
    },
    changeHeight() {
      let allNode = 0
      // eslint-disable-next-line no-underscore-dangle
      const nodes = this.$refs.tree.chart._chartsViews[0]._data._graphicEls
      nodes.forEach(node => {
        if (node !== undefined) {
          allNode += 1
        }
      })
      this.computeHeight(allNode)

      this.$nextTick(() => {
        this.$refs.tree.resize()
      })
    },
    initNodeCount(json, level) {
      this.nodeCount += json.length
      if (level < this.tree.series[0].initialTreeDepth) {
        if (json.length > 0) {
          json.forEach(c => {
            const nextLevel = level + 1
            if (c.children !== undefined && c.children !== null) {
              this.initNodeCount(c.children, nextLevel)
            }
          })
        }
      }
    },
    computeHeight(nodeCount) {
      const h = 10 * nodeCount < 220 ? 220 : 10 * nodeCount
      this.chartHeight = h
    },
  },
}
</script>

<style>
.echarts{
  width:100%;
  height:100%;
  min-height: 220px;
}
</style>
